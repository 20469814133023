import UtilsRequest from '@/utils/UtilsRequest';

const API_URL = 'Poli/';

class PoliService {
    getPoli(enteId, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("enteId", enteId);

        UtilsRequest.get(API_URL + "GetPoli", null,
        successFn,
        failFn
        );
    }

    getPolo(id, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("idPolo", id);

        UtilsRequest.get(API_URL + "GetPolo", parametri,
        successFn,
        failFn
        );
    }
    
    getTemplatePolo(successFn, failFn){
        const parametri = new URLSearchParams();

        UtilsRequest.get(API_URL + "GetTemplatePolo", null,
        successFn,
        failFn
        );
    }

    createPolo(polo, successFn, failFn){
        UtilsRequest.post(API_URL + "CreatePolo", polo, 
        successFn,
        failFn
        );
    }

    updatePolo(polo, successFn, failFn){
        UtilsRequest.put(API_URL + "UpdatePolo", polo, 
        successFn,
        failFn
        );
    }

    deletePolo(id, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("id", id);
        
        UtilsRequest.delete(API_URL + "DeletePolo", parametri, 
        successFn,
        failFn
        );
    }
}

export default new PoliService();
