<template>
  <v-form ref="form" class="mx-2" method="post" enctype="multipart/form-data">
    <v-container id="POLI" fluid tag="section">
      <p>{{ $t("poli.polo") }}</p>
      <v-row>
        <v-col :cols="isEditPoli ? '4' : '12'">
          <base-material-card
            color="white"
            iconcolor="darkred"
            icon="mdi-alpha-p-circle"
            :title="$t('poli.titolo')"
            class="px-5 py-3"
          >
            <v-container v-if="isCreatedCorrectly" v-resize="onContainerChange">
              <v-row>
                <v-col>
                  <v-card-title>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('poli.ricerca')"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                </v-col>
                <v-col align="end">
                  <v-btn color="darkred" width="150px" @click="onNuovoPolo">
                    <v-icon left> mdi-plus-circle-outline </v-icon>
                    {{ $t("poli.nuovoPolo") }}
                  </v-btn>
                  <v-btn color="darkred" width="180px" @click="onExportGrid"  v-show="false">
                    <v-icon left> mdi-export </v-icon>
                    {{ $t("esporta") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <v-data-table
              style="max-height:600px; overflow:auto"
              id="tblPoli"
              :items="poli.source"
              :headers="headersColumns"
              :search="search"
              :value="[poli.selected]"
              @click:row="onTableRowClick"
              single-select
              :items-per-page="-1"
              class="elevation-1"
              :loading="isloading_dataTable"
              loading-text="Caricamento dati in corso..."
            >
            </v-data-table>
          </base-material-card>
        </v-col>

        <v-col v-if="isEditPoli" cols="8">
          <base-material-card
            color="white"
            iconcolor="darkred"
            icon="mdi-alpha-p-circle-outline"
            :title="$t('poli.polo') + ': ' + poli.selected.cSigla"
            class="px-5 py-3"
          >
            <v-card flat>
              <v-row>
                <v-card-title
                  class="black--text"
                  style="margin-bottom: 20px; margin-top: 20px"
                  >{{ $t("anagrafica") }}
                </v-card-title>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="poli.selected.cSigla"
                    :label="$t('poli.siglaObbl')"
                    :rules="[rules.required]"
                    maxlength="10"
                    :disabled="shouldDisable"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="poli.selected.cDescrizione"
                    :label="$t('poli.descrizioneObbl')"
                    :rules="[rules.required]"
                    maxlength="50"
                    :disabled="shouldDisable"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    :items="eEnti"
                    return-object
                    item-text="cDescrizione"
                    item-value="id"
                    :label="$t('poli.enteObbl')"
                    :rules="[rules.selectRequired]"
                    v-model="poli.selected.enteDTO"
                    :disabled="shouldDisable"
                    @change="onEnteChanged($event)"
                  >
                  </v-select>
                </v-col>
                <v-col>
                  <v-select
                    :items="eUtenti"
                    v-model="poli.selected.userDTO"
                    return-object
                    item-text="cNomeCognome"
                    item-value="id"
                    :label="$t('poli.utente')"
                    :disabled="shouldDisable"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row> </v-row>

              <v-divider class="mx-4"></v-divider>
              <v-row
                style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px"
              >
                <v-alert
                  id="alertDettaglio"
                  shaped
                  :value="isAlertDetailVisible"
                  :type="alertType"
                  dismissible
                >
                  {{ messaggioAlert }}
                </v-alert>
              </v-row>

              <v-row
                style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px"
              >
                <v-btn
                  tile
                  color="darkred"
                  v-if="!isHidden"
                  v-on:click="isHidden = true"
                  @click="onEditClick"
                >
                  <v-icon left> mdi-pencil </v-icon>
                  {{ $t("modifica") }}
                </v-btn>
                <v-btn
                  tile
                  color="darkred"
                  v-if="isHidden"
                  @click="onSaveClick"
                >
                  <v-icon left> mdi-content-save </v-icon>
                  {{ $t("salva") }}
                </v-btn>
                <v-btn tile color="darkred" @click="onDisattivaClick">
                  <v-icon left> mdi-delete </v-icon>
                  {{ $t("disattiva") }}
                </v-btn>
                <v-btn
                  id="btnChiudi"
                  tile
                  color="darkred"
                  @click="onCloseDetail"
                >
                  <v-icon left> mdi-door-open </v-icon>
                  {{ $t("chiudi") }}
                </v-btn>
                <v-dialog v-model="dialogDisattivaPolo" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Sei sicuro di voler disattivare il polo selezionato?
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="onCloseDisattivaPolo"
                        >Annulla</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="onConfirmDisattivaPolo"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-card>
          </base-material-card>
        </v-col>
      </v-row>

      <v-row>
        <v-alert
          id="alertGrid"
          shaped
          :value="isAlertGridVisible"
          :type="alertType"
          dismissible
        >
          {{ messaggioAlert }}
        </v-alert>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";

import i18n from "@/i18n";
import Utils from "@/utils/Utils";

import TipologicheService from "@/service/TipologicheService";
import EntiService from "@/service/EntiService";
import CapitoliService from "@/service/CapitoliService";
import PoliService from "@/service/PoliService";
import UtentiService from "@/service/UtentiService";

@Component
export default class Poli extends Vue {
  poli = {
    selected: null,
    source: [],
  };

  headersColumns = [
    {
      text: "Sigla",
      align: "start",
      value: "cSigla",
    },
    { text: "Descrizione", value: "cDescrizione" },
    { text: "Ente", value: "enteDTO.cDescrizione" },
    { text: "Utente", value: "userDTO.cNomeCognome" },
  ];

  eEnti = [];

  eUtenti = [];

  isEditPoli = false;
  isNewPolo = false;
  isCreatedCorrectly = false;
  isAlertGridVisible = false;
  isAlertDetailVisible = false;
  isloading_dataTable = false;
  shouldDisable = true;
  enteShouldDisable = true;
  isHidden = false;
  saveClicked = false;
  isNewUser = false;
  dialogDisattivaPolo = false;

  poloTemplate = null;

  dimension = 0;

  messaggioAlert = "";
  alertType = "error";
  search = "";

  rules = Utils.rules;

  created() {
    let verLoadingPage = Utils.verifyLoadingPage(
      this.$store,
      "W_PROFILAZIONEUTENTE"
    );
    switch (verLoadingPage) {
      case 0: // All OK
        this.isCreatedCorrectly = true;
        var enteId = this.$store.state.enteSel.id;
        this.loadPoli(0);
        this.loadEnti();
        this.getTemplatePolo();
        break;

      case 1: // Utente non loggato
        AuthService.logout();
        break;

      case 2: // Ente non selezionato
        this.$router.push({ name: "Dashboard" });
        break;

      case 3: // Permesso alla pagina non presente
        this.$router.push({ name: "Dashboard" });
    }
  }

  onEditClick() {
    this.shouldDisable = false;
  }

  onDisattivaClick() {
    this.dialogDisattivaPolo = true;
  }

  onSaveClick() {
    // Verifico se i campi della form sono tutti validi.
    // Per funzionare nel template ci deve essere un oggetto di tipo V-FORM
    this.saveClicked = true;
    var formIsValid = this.$refs.form.validate();
    if (!formIsValid) {
      this.alertType = "error";
      // In caso di errore del salvataggio continuare con l'editing
      this.messaggioAlert = i18n.t("erroreCampiObbligatori");
      this.showHide_alertDetail(8000);
    } else {
      if (this.poli.selected.id > 0) {
        // Salvo il dato sul DB
        this.savePoloDto(this.poli.selected.id);
      } else {
        // Creo il nuovo record
        this.createPoloDto();
      }
    }
  }

  onCloseDetail() {
    this.isEditPoli = false;
    this.onContainerChange();
    this.shouldDisable = true;
    this.isHidden = false;
  }

  onExportGrid() {
    // TODO
  }

  onNuovoPolo() {
    this.saveClicked = false;
    this.isNewPolo = true;
    this.isEditPoli = true;
    this.shouldDisable = false;
    this.isHidden = true;

    this.poli.selected = Object.assign({}, this.poloTemplate);

    this.onContainerChange();

    //this.manageEntiVisibility(true);

    this.onEnteChanged();

    this.$refs.form.resetValidation();
  }

  onTableRowClick(poloSel) {
    // Setto la riga selezionata
    this.poli.selected = poloSel;

    console.log("Polo selezionato: ");
    console.log(this.poli.selected);

    //this.manageEntiVisibility(false);
    // TODO
    this.onEnteChanged();

    this.isHidden = false;
    this.shouldDisable = true;
    this.isEditPoli = true;
  }

  onContainerChange() {
    let self = this;
    // console.log("onContainerChange");
    self.$nextTick(function () {
      self.dimension = document.getElementById("tblPoli").offsetWidth;
      return self.dimension;
    });
  }

  onCloseDisattivaPolo() {
    this.dialogDisattivaPolo = false;
  }

  onEnteChanged() {
    if (
      !Utils.isNullOrUndefined(this.poli.selected.enteDTO) &&
      this.poli.selected.enteDTO.id != -1
    ) {
      // Carico gli utenti
      this.loadUtenti(this.poli.selected.enteDTO.id);
    }
  }

  onConfirmDisattivaPolo() {
    this.disattivaPoloDto(this.poli.selected.id);
    this.dialogDisattivaPolo = false;
  }

  showHide_alertGrid(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertGridVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertGridVisible = false;
      console.log(
        "hide alert after " + millisecondInterval / 1000 + " seconds"
      );
    }, millisecondInterval);
  }

  showHide_alertDetail(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertDetailVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertDetailVisible = false;
      //console.log("hide alert after " + millisecondInterval/1000 + " seconds");
    }, millisecondInterval);
  }

  /// ***********************
  /// DATABASE
  /// ***********************

  getTemplatePolo() {
    var self = this;

    PoliService.getTemplatePolo(
      (resp) => {
        console.log("getTemplatePolo:");
        console.log(resp);

        self.poloTemplate = resp;
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  loadPoli(enteId, storico) {
    var self = this;
    self.isloading_dataTable = true;
    PoliService.getPoli(
      enteId,
      (resp) => {
        console.log("getPoli");
        console.log(resp);
        self.isloading_dataTable = false;

        self.poli.source = resp;
      },
      (err) => {
        console.log("Errore getPoli");
        console.log(err);
        self.isloading_dataTable = false;
      }
    );
  }

  loadEnti() {
    var self = this;

    EntiService.getEnti(
      (resp) => {
        console.log("GetEnti");
        console.log(resp);

        self.eEnti = resp;
      },
      (err) => {
        console.log("Errore GetEnti");
        console.log(err);
      }
    );
  }

  loadUtenti(enteId) {
    var self = this;
    UtentiService.getUsersEnti(
      enteId,
      (resp) => {
        console.log("GetUtenti");
        console.log(resp);

        self.eUtenti = resp;
      },
      (err) => {
        console.log("Errore GetUtenti");
        console.log(err);
      }
    );
  }

  savePoloDto(id) {
    var self = this;

    PoliService.updatePolo(
      self.poli.selected,
      (resp) => {
        console.log("updatePolo");
        console.log(resp);
        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
          // Aggiorno la griglia principale
          self.updateGridPolo(id);
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        self.isHidden = !self.isHidden;
        self.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  createPoloDto() {
    var self = this;

    PoliService.createPolo(
      self.poli.selected,
      (resp) => {
        console.log("createPolo");
        console.log(resp);
        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertGrid(5000);
          // Aggiorno la griglia principale
          self.updateGridPolo(resp.idNewObject);
          self.isNewServizio = false;
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        self.isHidden = !self.isHidden;
        self.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  updateGridPolo(id, onSuccess) {
    var self = this;

    PoliService.getPolo(
      id,
      (resp) => {
        console.log("Polo:");
        console.log(resp);

        var poloTmp = resp;

        if (typeof onSuccess === "function") onSuccess(resp);
        // Aggiorno la griglia
        var found = false;
        for (let i = 0; i < self.poli.source.length; i++) {
          var item = self.poli.source[i];
          if (item.id == id) {
            found = true;
            self.poli.source.splice(i, 1);
            self.poli.source.push(poloTmp);
            self.poli.selected = poloTmp;
            break;
          }
        }
        if (!found) {
          // Caso di nuovo oggetto
          self.poli.source.push(poloTmp);
          self.poli.selected = poloTmp;
        }
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertGrid(8000);
      }
    );
  }

  disattivaPoloDto(id) {
    var self = this;

    PoliService.deletePolo(
      self.poli.selected.id,
      (resp) => {
        console.log("deletePolo");
        console.log(resp);

        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertGrid(5000);
          // Azzero l'oggetto
          self.poli.selected = null;
          // Ricarico la griglia
          self.loadPoli();
          self.isEditPoli = false;
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
        }

        self.isHidden = !self.isHidden;
        self.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(5000);
      }
    );
  }
}
</script>