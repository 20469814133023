import UtilsRequest from '@/utils/UtilsRequest';

const API_URL = 'CapitoliSpesa/';

class CapitoliService {
    getTemplateCapitolo(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTemplateCapitoloSpesa", null,
        successFn,
        failFn
        );
    }
    
    getCapitoliSpesa(enteId, storico, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("storico", storico);
        parametri.append("enteId", enteId);

        UtilsRequest.get(API_URL + "GetCapitoliSpesa", parametri,
        successFn,
        failFn
        );
    }

    getCapitoloSpesa(id, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("id", id);
        UtilsRequest.get(API_URL + "GetCapitoloSpesa", parametri, 
        successFn,
        failFn
        );
    }

    createCapitolo(capitolo, successFn, failFn){
        UtilsRequest.post(API_URL + "CreateCapitoloSpesa", capitolo, 
        successFn,
        failFn
        );
    }

    updateCapitolo(capitolo, successFn, failFn){
        UtilsRequest.put(API_URL + "UpdateCapitoloSpesa", capitolo, 
        successFn,
        failFn
        );
    }

    deleteCapitolo(id, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("id", id);
        UtilsRequest.delete(API_URL + "DeleteCapitoloSpesa", parametri, 
        successFn,
        failFn
        );
    }
}

export default new CapitoliService();
